<template>
<!-- 我的订单 -->
	<div class="main-container">
		<div class="side-header">
			<div class="name">
				我的订单
			</div>
		</div>
		<div class="main-content">
      <div class="order-list">
        <div class="order-item" v-for="(item,index) in list" :key="index">
          <div class="order-title">
            <div class="order-time">下单时间：{{ item.createTime | stringData}}</div>
            <div class="order-num">订单号：{{item.orderSn}}</div>
          </div>
          <div class="order-content">
            <div class="order-info">
              <img :src="item.thumb" class="order-img" alt="">
              <div class="order-intro">
                <div class="name">{{item.goodsName}}</div>
                <div class="box" v-if="item.type == 1">
                  <div class="time">{{item.examDate}}</div>
                  <div class="adress">{{item.examAddress}}</div>
                </div>
                <div class="intro" v-else>{{item.description}}</div>
              </div>
              <div class="order-money">
                ￥{{item.payment}}
              </div>
            </div>
            <!-- 订单状态  0未支付 1 已支付 2订单取消  9 用户支付失败 -->
            
            <div class="order-btn" v-if="!item.status">
              <div class="box-pay" v-preventReClick @click="onPay(item.orderId)">
                <div class="pay-txt">去支付</div>
                <djs class="pay-time" :endTime="item.createTime" @timeUp="callback(item)"/>
              </div>
              <div class="btn-del" @click="cancelOrder(item.orderId,index)">取消订单</div>
            </div>
            <div class="order-btn" v-else>
              <div class="status">{{item.status | statusFilter}}</div>
              <div class="btn-del" @click="delectOrder(item.orderId,index)">删除订单</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-pagination
          background
          :current-page="page"
          layout="prev, pager, next"
          @current-change="onChangePage"
          :hide-on-single-page="true"
          :page-count="totalPage">
        </el-pagination>
      </div>
      <el-empty description="暂无数据" v-if="!list.length"></el-empty>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import djs from "@/components/djs";
export default {
  filters: {
    stringData: function (date) {
      return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    statusFilter(status) {
      const statusMap = {
        0: "未支付",
        1: "已支付",
        2:'订单取消',
        9:'用户支付失败'
      };
      return statusMap[status];
    },
  },
  components:{ djs },
  data() {
    return {
      time:'',
      page:1,
      list:[],
      totalPage:0,
    };
  },
  created(){
    this.getOrderList(this.page);
  },
  methods: {
    getOrderList(page){
      this.$http
      .get('/api/web/order/getOrderList?page='+page)
      .then((res) => {
        this.list=res.data.orderList;
        this.totalPage=res.data.totalPage;    
      })
    },
    onChangePage(val){
      this.page=val;
      this.getOrderList(val)
    },
    onPay(id){
      this.$http
        .post('/api/web/order/aliPayOrderById',{orderId:id})
        .then((res) => {
        }).catch(err=>{
          if(err.status == 10000){
            this.$toast(err.msg)
          }else{
            document.querySelector('body').innerHTML = err
            document.forms[0].submit()
          }
        })
    },
    delectOrder(id,index){
      this.$confirm('此操作将永久删除该订单, 是否继续?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        this.$http
        .get('/api/web/order/delOrder?orderId='+id)
        .then((res) => {
          this.list.splice(index,1)
          this.$message({
            type: 'success',
            message: res.msg || '删除成功!'
          });
        }).catch(err=>{
          this.$message({
            type: 'info',
            message: err.msg|| '删除失败!'
          }); 
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    cancelOrder(id,index){
      this.$confirm('是否取消订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        this.$http
        .get('/api/web/order/cancelOrder?orderId='+id)
        .then((res) => {
          this.getOrderList(this.page);
          this.$message({
            type: 'success',
            message: res.msg || '取消成功!'
          });
        }).catch(err=>{
          this.$message({
            type: 'info',
            message: err.msg|| '取消失败!'
          }); 
        })
      })
    },
    callback(item){
      item.status=2
    },
  },
};
</script>

<style lang='less' scoped>
.main-container {
  background: #fff;
  min-height: 750px;
  .side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 20px 0 40px;
    border-bottom: 1px solid #F5F5F5;
    .name {
      font-size: 16px;
      color: #333;
    }
    .operate {
      line-height: 68px;
      padding: 0 20px;
      color: #D71A18;
      cursor: pointer;
    }
  }
  .main-content {
    padding:10px 30px 40px;
    .order-list{
      padding: 30px 0;
      line-height: 1;
      .order-item{
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
        .order-title{
          display: flex;
          padding: 0 20px;
          height: 50px;
          line-height: 50px;
          background: #f5f5f5;
          color: #666;
          .order-num{
            margin-left: 60px;
            font-size: 16px;
          }
        }
        .order-content{
          display: flex;
          .order-info{
            display: flex;
            align-items: center;
            flex: 1;
            padding:30px 60px 30px 20px;
          }
          .order-img{
            width: 160px;
            height: 90px;
          }
          .order-intro{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            height: 90px;
            margin-left: 30px;
            flex: 1;
            .name{
              margin-top: 6px;
              font-size: 18px;
              color: #333;
            }
            .box{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
            .time{
              margin-top: auto;
              margin-bottom: 10px;
              color: #666;
            }
            .adress{
              color: #666;
            }
            .intro{
              font-size: 16px;
              color: #666;
            }
          }
          .order-money{
            font-weight: bold;
            color: #D71A18;
            font-size: 24px;
          }
          .order-btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 180px;
            border-left: 1px solid #f5f5f5;
            .box-pay{
              padding: 8px 30px;
              height: 50px;
              background: #D71A18;
              border-radius: 4px;
              margin: 0 auto 10px;
              color: #fff;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              font-weight: 700;
              font-size: 14px;
              cursor: pointer;
              .pay-txt{
                font-size: 15px;
              }
              .pay-time{
                font-size: 13px;
              }
            }
            .status{
              color: #666;
              font-size: 14px;
              margin-bottom: 20px;
            }
            .brn-del{
              color: #333;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
